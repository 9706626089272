<template>
  <ExpandedCard title="Timeline" :loading="loading_items">
    <template v-slot:default>
      <v-card flat>
        <v-card-text>
          <v-row dense align="stretch" class="fill-height">
            <v-col :cols="mdAndUp ? 6 : 12" class="timeline-wrapper">
              <v-card elevation="1" class="pa-4" min-height="500">
                <v-card-title>
                  <v-spacer></v-spacer>
                  <cMenu
                    color="primary"
                    dense
                    close-on-content-click
                    depressed
                    label="Filter"
                    left-icon="mdi-filter"
                  >
                    <v-list dense>
                      <v-list-item
                        @click="activeFilter = filter.value"
                        v-for="filter in filters"
                        :key="filter.value"
                      >
                        <v-list-item-title>
                          {{ filter.text }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </cMenu>
                </v-card-title>
                <v-card-text
                  v-if="items.length"
                  style="overflow-y: auto; max-height: 700px"
                >
                  <v-timeline align-top dense>
                    <v-hover
                      v-slot="{ hover }"
                      v-for="(item, index) in items"
                      :key="index"
                    >
                      <v-timeline-item
                        :key="index"
                        color="teal lighten-3"
                        small
                      >
                        <v-row
                          dense
                          @click="setActiveItem(item)"
                          :class="{ 'darken--text whitesmoke': hover }"
                          class="primary--text cursor-pointer pt-1 mb-1"
                        >
                          <v-col cols="2">
                            <strong>{{ item.created_at | from_now }}</strong>
                          </v-col>
                          <v-col>
                            <strong>{{ item.description }}</strong>
                          </v-col>
                        </v-row>
                        <template v-slot:icon>
                          <v-avatar class="border-1">
                            <v-img
                              :lazy-src="settings.loader"
                              contain
                              :src="item.causer_user.image_url"
                            />
                          </v-avatar>
                        </template>
                      </v-timeline-item>
                    </v-hover>
                  </v-timeline>
                </v-card-text>
                <Empty
                  v-else
                  class="fill-height align-self-center"
                  icon="mdi-timeline-alert"
                  headline="No activity found"
                >
                </Empty>
                <v-card-actions dense class="pa-1" v-if="has_more_items">
                  <v-spacer />
                  <v-btn
                    color="primary"
                    depressed
                    @click="loadMoreItems"
                    :disabled="!has_more_items || loading_more_items"
                    class="text-none px-5"
                    :loading="loading_more_items"
                  >
                    Load more
                  </v-btn>
                  <v-spacer />
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col :cols="mdAndUp ? 6 : 12" class="d-flex">
              <v-card
                elevation="1"
                min-height="500"
                class="fullwidth align-self-stretch d-flex"
                v-if="activeItem"
              >
                <v-card-text class="align-self-center">
                  <v-row dense class="my-5">
                    <v-spacer></v-spacer>
                    <span class="title primary--text">
                      {{ activeItem.description }}
                    </span>
                    <v-spacer></v-spacer>
                  </v-row>
                  <ActivityFileViewer
                    class="align-self-center mx-auto"
                    :activity="activeItem"
                    :size="200"
                  />
                  <v-row
                    dense
                    class="my-5"
                    v-if="activeItem.subject && can_navigate(activeItem)"
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      depressed
                      @click="navigate(activeItem)"
                      class="px-5 text-none"
                    >
                      Go to {{ subject_type(activeItem) }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-avatar v-else size="auto" width="100%">
                <v-img
                  :lazy-src="settings.loader"
                  contain
                  :src="image"
                  max-height="250"
                ></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </ExpandedCard>
</template>

<script>
import ExpandedCard from '../components/ExpandedCard.vue'
import ActivityFileViewer from '@/modules/Timeline/components/ActivityFileViewer.vue'
import { fetch_services } from '@/services/fetch/fetch_services'
import _ from 'lodash'
export default {
  mixins: [fetch_services],
  components: { ExpandedCard, ActivityFileViewer },
  created() {
    this.loadItems(
      `api/activities?per_page=20`,
      true,
      (data) => {
        if (data && data.data.length) {
          this.setActiveItem(data.data[0])
        }
      },
      null
    )
  },
  data: (vm) => ({
    activeFilter: 'all',
    filters: [
      { text: 'All', value: 'all' },
      { text: 'Files', value: 'files' },
      { text: 'Tasks', value: 'project.task' }
    ],
    image: require('@/assets/svgs/images/card-timeline.svg?internal').default
  }),
  watch: {
    activeFilter(val) {
      this.loadItems(
        `api/activities?per_page=20&filter=${val}`,
        true,
        null,
        null
      )
    }
  },
  methods: {
    setActiveItem(item) {
      this.activeItem = item
    },
    can_navigate(activity) {
      return activity.subject_type === 'App\\Project'
    },
    navigate(activity) {
      if (activity.subject_type === 'App\\Project')
        this.$router.push({ name: 'hq', params: { id: activity.subject_id } })
    },
    subject_type(activity) {
      return activity.subject_type.replace('App\\', '')
    }
  }
}
</script>

<style lang="scss" scoped></style>
